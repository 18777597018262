import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCardFeed, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import { BlockSerializer } from "../utils/BlockSerializer"

/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tag = ({ data, location, pageContext }) => {
    const tag = data.sanityCategory

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout>
                <div className="container">
                    <h1>{tag.name}</h1>
                    <PostCardFeed data={data} />
                    <footer className="tag-header">
                        {tag._rawDescription ? <BlockSerializer>{tag._rawDescription}</BlockSerializer> : null}
                    </footer>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        sanityTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allSanityPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tag

export const pageQuery = graphql`
    query sanityTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        sanityCategory(slugWithRedirectValidation: { slug: {current: {eq: $slug}}}) {
            ...SanityCategoryFields
        }
        allSanityPost(
            sort: { order: DESC, fields: [publishedAt] },
            filter: {categories: {elemMatch: {slugWithRedirectValidation: { slug: {current: {eq: $slug}}}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...SanityPostFields
                }
            }
        }
        allSanitySiteSettings {
            edges {
                node {
                    authorSlug {
                        ...SlugWithRedirectValidationFields
                    }
                }
            }
        }
    }
`
